<template>
	<div class="list-page">
		<div class="list flex-direction-column" v-if="list.length > 0">
			<div class="list_box flex-direction-column">
				<div
					v-for="(item,index) in list"
					:key="item.id">
					<div
						class="list_item flex-space-between"
						@click="itemClick(item)"
					>
						<div class="list_item_left flex">
							<img src="../../assets/image/icon/list_sanjiao.png">
							<p style="width: 800px;white-space: nowrap;overflow: hidden;text-overflow:ellipsis;">{{ item.title }}</p>
						</div>
						<div class="list_item_right">{{ item.tag || item.create_time }}</div>
					</div>
					<div class="line" v-if="(index+1) % 5==0 "></div>
				</div>
			</div>
		</div>

		<Lack
			v-if="list.length == 0"
			text="暂无内容"
			minHeight="260px"
			imgWidth="102px"
			imgHeight="68px"
			:imgSrc="require('../../assets/image/img/15.png')"
		></Lack>
		<div class="pagination">
			<el-pagination @current-change="currentChange" :page-size="per_page" background layout="prev, pager, next"
										 :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
import Lack from '../../components/lack/lack.vue'
import {downloadFile} from '@/utils/tools'
// import { EventBus } from '@/utils/event-bus'
import {postColumnList, postServeDetail, postSearchFor} from '@/service/api/index'

export default {
	props: {
		id: {
			type: Number,
			default() {
				return null
			}
		},
		isDown: {
			type: Number,
			default() {
				return null
			}
		},
		isSearch: {
			type: Number,
			default() {
				return null
			}
		}
	},
	components: {Lack},
	data() {
		return {
			listId: 0,
			list: [],
			total: 0,
			per_page: 15,
		}
	},

	mounted() {
		if (this.$route.params.info) {
			sessionStorage.setItem('params', this.$route.params.info)
		}
		// EventBus.$on('listId', (res) => {
		//   console.log('res', res)
		//   // this.postColumnList(res.id, 1)
		// })
	},

	methods: {
		postSearchFor(page) {
			postSearchFor({key_words: this.$route.params.key_words, page}).then((res) => {
				// console.log('res', res)
				this.list = Object.freeze(res.msg.data)
				this.total = res.msg.total
				this.per_page = res.msg.per_page
			})
		},
		postColumnList(id, page) {
			postColumnList({id, page}).then((res) => {
				// console.log('res', res)
				this.list = Object.freeze(res.msg.data)
				this.total = res.msg.total
				this.per_page = res.msg.per_page
			})
		},
		postServeDetail(page) {
			postServeDetail({id: 1, page}).then((res) => {
				// console.log(res)
				this.list = Object.freeze(res.msg.data)
				this.total = res.msg.total
				this.per_page = res.msg.per_page
			})
		},
		itemClick(item) {
			if (item.type_two == 2) return downloadFile(item.url, item.title)
			if (this.isDown == 1) {
				downloadFile(item.url, item.title)
			} else if (this.isSearch == 1) {
				this.$router.replace({
					name: 'pudetail2', params: {
						info: JSON.stringify({
							id: item.id,
							status: 0,
							type: 3,
						})
					}
				});
			} else {
				this.$router.replace({
					name: 'pudetail2', params: {
						info: JSON.stringify({
							id: item.id,
							status: 0,
						})
					}
				});
			}
		},
		// 页码发生改变
		currentChange(page) {
			// console.log(page)
			if (this.isDown == 1) {
				this.postServeDetail(page)
			} else {
				this.postColumnList(this.listId, page)
			}
		}
	},
	watch: {
		'$store.state.listId': {
			handler(val) {
				if (this.isSearch != 1) {
					this.postColumnList(val, 1)
					this.listId = val
				}
			},
			deep: true,
			immediate: true
		},
		// id: {
		//   handler: function (val) {
		//     this.listId = val
		//     this.postColumnList(val, 1)
		//   },
		//   immediate: false,
		//   deep: true
		// },
		isDown: {
			handler: function (val) {
				if (val == 1) {
					this.postServeDetail(val)
				}
			},
			immediate: true
		},
		// isSearch: {
		// 	handler: function (val) {
		// 		if (val == 1) {
		// 			this.postSearchFor(val)
		// 		}
		// 	},
		// 	immediate: true
		// },
		"$route.params.key_words": {
			handler: function (val) {
				if (this.$route.path == '/search') {
					this.postSearchFor(val)
				}
			},
			immediate: true
		},
	}
}
</script>

<style lang="less" scoped>
/deep/ .el-pager li {
	font-weight: normal;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
	background-color: #212F82;
	color: #fff;
}

.pagination {
	display: flex;
	justify-content: center;
	margin-top: 29px;
}

.list-page {
	min-height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
</style>
